@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&family=Poppins:wght@300;400;500;600;700&family=Raleway:wght@300;400;500;600;700&family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');
html {
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(360deg, #fff8e7 0%, #ffffff 100%); */
  /* background: transparent!important; */
  height: 100vh;
  padding: 0 !important;
  overflow-y: auto;
  /* scroll-behavior: smooth; */
}

/* Customize the scrollbar */
body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiBox-root .MuiStepper-vertical .Mui-disabled span {
  min-height: 0px;
}
.MuiBox-root .MuiStepper-vertical .Mui-completed span {
  min-height: 0px;
}
.MuiBox-root .MuiStepper-vertical .Mui-active span {
  min-height: 0px;
}
