.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.adding-padding {
  padding-top: 80px;
}

#fuse-splash-screen {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 99999;
  pointer-events: none;
}

#fuse-splash-screen .center {
  display: block;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

#fuse-splash-screen .logo {
  width: 128px;
  margin: 0 auto;
}

#fuse-splash-screen .logo img {
  filter: drop-shadow(0px 10px 6px rgba(0, 0, 0, 0.2));
}

#fuse-splash-screen .spinner-wrapper {
  display: block;
  position: relative;
  width: 100%;
  min-height: 100px;
  height: 100px;
}

#fuse-splash-screen .spinner-wrapper .spinner {
  position: absolute;
  overflow: hidden;
  left: 50%;
  margin-left: -50px;
  animation: outer-rotate 2.91667s linear infinite;
}

#fuse-splash-screen .spinner-wrapper .spinner .inner {
  width: 100px;
  height: 100px;
  position: relative;
  animation: sporadic-rotate 5.25s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

#fuse-splash-screen .spinner-wrapper .spinner .inner .gap {
  position: absolute;
  left: 49px;
  right: 49px;
  top: 0;
  bottom: 0;
  border-top: 10px solid;
  box-sizing: border-box;
}

#fuse-splash-screen .spinner-wrapper .spinner .inner .left,
#fuse-splash-screen .spinner-wrapper .spinner .inner .right {
  position: absolute;
  top: 0;
  height: 100px;
  width: 50px;
  overflow: hidden;
}

#fuse-splash-screen .spinner-wrapper .spinner .inner .left .half-circle,
#fuse-splash-screen .spinner-wrapper .spinner .inner .right .half-circle {
  position: absolute;
  top: 0;
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  /*                border: 10px solid #61DAFB;
    */
  border: 10px solid #61dafb;
  /* border: 10px solid #e3a33d; */
  border-bottom-color: transparent;
  border-radius: 50%;
}

.phone-input-light:hover {
  background: #0000000f !important;
}
.phone-input-dark:hover {
  background: #171717 !important;
}

#fuse-splash-screen .spinner-wrapper .spinner .inner .left {
  left: 0;
}

#fuse-splash-screen .spinner-wrapper .spinner .inner .left .half-circle {
  left: 0;
  border-right-color: transparent;
  animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
  -webkit-animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

#fuse-splash-screen .spinner-wrapper .spinner .inner .right {
  right: 0;
}

#fuse-splash-screen .spinner-wrapper .spinner .inner .right .half-circle {
  right: 0;
  border-left-color: transparent;
  animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
  -webkit-animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

@keyframes outer-rotate {
  0% {
    transform: rotate(0deg) scale(0.5);
  }

  100% {
    transform: rotate(360deg) scale(0.5);
  }
}

@keyframes left-wobble {
  0%,
  100% {
    transform: rotate(130deg);
  }

  50% {
    transform: rotate(-5deg);
  }
}

@keyframes right-wobble {
  0%,
  100% {
    transform: rotate(-130deg);
  }

  50% {
    transform: rotate(5deg);
  }
}

@keyframes sporadic-rotate {
  12.5% {
    transform: rotate(135deg);
  }

  25% {
    transform: rotate(270deg);
  }

  37.5% {
    transform: rotate(405deg);
  }

  50% {
    transform: rotate(540deg);
  }

  62.5% {
    transform: rotate(675deg);
  }

  75% {
    transform: rotate(810deg);
  }

  87.5% {
    transform: rotate(945deg);
  }

  100% {
    transform: rotate(1080deg);
  }
}

.makeStyles-header-453 {
  display: none !important;
}

.makeStyles-topBg-451 {
  background: #ffffff !important;
}

.react-tel-input .special-label {
  background-color: transparent;
  display: none;
}

.react-tel-input .form-control {
  padding-top: 27px;
  padding-bottom: 27px;
  background: transparent;
  border: none;
}
.react-tel-input .form-control:hover,
.react-tel-input .form-control:focus {
  border: none !important;
  box-shadow: none !important;
}
.react-tel-input.inputtextfielddark .form-control {
  background: #eeeeee;
  color: #000;
  width: 100%;
  border-radius: 50px;
}
.react-tel-input.inputtextfielddark .form-control:hover,
.react-tel-input.inputtextfielddark .form-control:focus {
  background: #eeeeee;
  color: #000;
}
.react-tel-input.inputtextfield .form-control {
  color: #fff;
  background: #e6ae55;
  width: 100%;
  border-radius: 50px;
}
.react-tel-input.inputtextfield .form-control:hover,
.react-tel-input.inputtextfield .form-control:focus {
  background: #454545;
  color: #fff;
}

/* .react-tel-input .form-control:hover {
	background: #FAEECB !important;
	background: rgba(0, 0, 0, 0.09) !important;
	/* filter: brightness(95%) !important; 
} */

/* datepicker style */
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}
.react-tel-input .country-list::-webkit-scrollbar {
  /* display: none; */
  width: 4px;
  color: #cea631;
  /* background-color: black; */
}
.react-tel-input .country-list::-webkit-scrollbar-thumb {
  width: 0.5px;
  background-color: rgba(128, 125, 122, 0.651);
  border-radius: 20px;
  cursor: pointer;
}
.css-nf6uuc-MuiPickerStaticWrapper-root {
  width: 100% !important;
  border: 2px solid orange;
  border-radius: 20px;
  /* overflow: hidden; */
}
.css-1wvgxus-MuiCalendarPicker-viewTransitionContainer {
  overflow: hidden !important;
}
.MuiPickersDay-current {
  color: orange !important;
  border: 2px solid orange !important;
}
.MuiPickersDay-daySelected {
  background: orange !important;
  color: white !important;
  font-weight: bold !important;
}
.searchfiledstyle .css-1wa3eu0-placeholder {
  color: #5e6068;
}
.searchfiledstyle {
  background: transparent;
}
.searchfiledstyle .css-1pahdxg-control,
.searchfiledstyle .css-yk16xz-control {
  background: transparent;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.09);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 7px 1px;
  border-color: '1px solid gray';
  border-radius: 25px;
  box-shadow: none;
}
.css-yk16xz-control .css-1d2jrbe-singleValue,
.css-1hwfws3,
.css-1sen79o,
.css-1f43avz-a11yText-A11yText,
.css-1hwfws3 {
  color: #fff;
}
.css-2613qy-menu {
  background: #000;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition:
    background-color 600000s 0s,
    color 600000s 0s;
  -webkit-box-shadow: 0 0 0 0 transparent inset;
  box-shadow: 0 0 0 0 transparent inset;
}
.css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 0 transparent inset;
  box-shadow: 0 0 0 0 transparent inset;
}
input {
  background: -internal-light-dark((transparent), rgb(59, 59, 59));
}

/*
* Dark Button
*/

.searchfiledDarkstyle .css-1wa3eu0-placeholder {
  color: rgb(167, 109, 2);
}
.searchfiledDarkstyle .css-yk16xz-control {
  background: #e6ae55;
  /* margin-left: 14px; */
  /* border-radius: 50px; */
  height: 55px;
  border-radius: 30px;
}
.searchfiledDarkstyle .css-yk16xz-control:hover,
.searchfiledDarkstyle .css-yk16xz-control:focus {
  border: transparent;
  background: #e6ae55 !important;
  border-radius: 30px !important;
}
.searchfiledstyle .css-yk16xz-control {
  background: #e6ae55;
}
.searchfiledstyle .css-yk16xz-control {
  background: #e6ae55;
  color: rgba(0, 0, 0, 0.38);
}
.css-1pahdxg-control {
  border: 1px solid whitesmoke;
  background: #e6ae55 !important;
  border-radius: 30px !important;
  height: 55px;
}
.css-1pahdxg-control:hover,
.css-1pahdxg-control:focus {
  border: 2px solid whitesmoke !important;
  background: #e6ae55 !important;
  border-radius: 30px !important;
  box-shadow: none !important;
  height: 55px;
}
.searchfiledstyle .css-1pahdxg-control:hover,
.searchfiledstyle .css-1pahdxg-control:focus {
  border: transparent;
  background: #e6ae55 !important;
  border-radius: 30px !important;
}
/* .react-tel-input .form-control {
	padding-top: 27px;
	padding-bottom: 27px;
	background: transparent;
	border: none;
} */
.react-tel-input .form-control:hover,
.react-tel-input .form-control:focus {
  /* background: #FAEECB !important; */
  /* background: transparent; */
  border: none;
}
.react-tel-input.inputtextfielddark .form-control {
  background: #ffffff !important;
  color: #000;
  width: 100%;
  height: 59px;
  border: 0.76321px solid #efd183;
  border-radius: 10px !important;
}
.react-tel-input.inputtextfielddark .form-control:hover,
.react-tel-input.inputtextfielddark .form-control:focus {
  border: 0.76321px solid #efd183 !important;
  background-color: rgba(0, 0, 0, 0.09) !important;
  /* background: #ffffff!important; */
  color: #000;
}
.react-tel-input.inputtextfield .form-control {
  color: #fff;
  background: #e3a33d;
  width: 100%;
  border-radius: 50px;
}
.react-tel-input.inputtextfield .form-control:hover,
.react-tel-input.inputtextfield .form-control:focus {
  background: #454545;
  color: #fff;
}
/* button:focus {
	outline: none;
} */
.MuiFilledInput-input input {
  box-shadow: none !important;
  outline: none;
}
.css-uenmug-MuiTablePagination-selectLabel,
.css-1wlr58e-MuiTablePagination-displayedRows {
  margin: 0px !important;
}
.css-va85tm {
  margin: 0px;
}

/* ////////////////////////// */
.circonf-wrapper {
  text-align: center;
}
.circonfsmall {
  margin: 1em;
  display: inline-block;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  border: 4px solid silver;
  animation: circ-anim 0.5s linear infinite;
}
.circonf {
  margin: 1em;
  display: inline-block;
  width: 5em;
  height: 5em;
  border-radius: 50%;
  border: 4px solid silver;
  animation: circ-anim 0.5s linear infinite;
}
.circonf-5 {
  border: 3px solid #3598db;
  /* border: 3px solid #e3a33d; */
  position: relative;
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.circonf-5:after {
  position: absolute;
  content: '';
  width: 80%;
  height: 80%;
  background: transparent;
  top: 50%;
  left: 50;
  margin-left: -40%;
  margin-top: -40%;
  border-radius: 50%;
  border: 2px solid #3598db;
  /* border: 2px solid #e3a33d; */
  box-sizing: border-box;
  border-bottom-color: transparent;
  border-left-color: transparent;
  animation: circ-anim 0.35s reverse linear infinite;
}

@keyframes circ-anim {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
